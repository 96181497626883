<script lang="ts" setup>
const MAX_VALUE = 99999;

const props = withDefaults(
  defineProps<{
    value?: number;
    size?: 's' | 'm';
    outlined?: boolean;
    limit?: number;
    dataTestId?: string;
  }>(),
  {
    value: 1,
    size: 'm',
    limit: MAX_VALUE,
  },
);

const emit = defineEmits<{
  (event: 'update:value', value: number): void;
}>();

const localValue = ref(props.value || 1);

const inputWidthStyle = computed(
  () => `${localValue.value.toString().length}ch`,
);

const increment = () => {
  localValue.value += 1;
};

const decrement = () => {
  localValue.value = Math.max(1, localValue.value - 1);
};

watch(
  () => props.value,
  () => {
    localValue.value = Math.min(Math.max(1, props.value), props.limit);
  },
);

watch(localValue, () => {
  const v = localValue.value.toString().replace(/\D/, '');
  if (!v || isNaN(+v)) localValue.value = 1;
  else if (+v < 1) localValue.value = 1;
  else if (+v > props.limit) localValue.value = props.limit;
  else localValue.value = +v;

  if (props.value !== localValue.value) emit('update:value', localValue.value);
});
</script>

<template>
  <div
    class="baseCounter"
    :class="[`baseCounter_${size}`, outlined && 'baseCounter_outlined']"
    :data-test-id="dataTestId"
  >
    <button
      @click="decrement"
      class="baseCounter__button"
      :data-test-id="dataTestId && `${dataTestId}-decrement`"
    >
      <SvgoSimpleMinus class="baseCounter__icon" />
    </button>
    <input
      v-model.number="localValue"
      type="number"
      inputmode="numeric"
      pattern="\d*"
      step="1"
      min="1"
      :max="limit"
      class="baseCounter__input"
      :data-test-id="dataTestId && `${dataTestId}-field`"
    />
    <button
      @click="increment"
      class="baseCounter__button"
      :data-test-id="dataTestId && `${dataTestId}-increment`"
    >
      <SvgoSimplePlus class="baseCounter__icon" />
    </button>
  </div>
</template>

<style lang="scss">
.baseCounter {
  $self: &;

  width: fit-content;
  height: 56px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  border: 1px solid #fff;

  &_s {
    height: 40px;

    #{$self}__input {
      min-width: 22px;
    }

    #{$self}__button {
      padding: 0 4px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  &_outlined {
    border-color: #121212;

    #{$self}__button:hover {
      background-color: #121212;
      color: #fff;
    }
  }

  &__input {
    width: v-bind(inputWidthStyle);
    min-width: 31px;
    border: none;
    border-radius: 0;
    padding: 0;
    text-align: center;
    outline: none;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #121212;
    background: #ffffff;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__button {
    flex: 1;
    border: none;
    cursor: pointer;
    background-color: #ffffff;
    color: #121212;
    padding: 0 12px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:first-child {
      padding-left: 40px;
    }

    &:last-child {
      padding-right: 40px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      color: #fff;
    }
  }

  & &__icon {
    width: 11px;
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}
</style>
