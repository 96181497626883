import { defineStore } from 'pinia';

import type { PickUpPointType, ProductType, SectionType } from '~/api/common';
import CommonAPI from '~/api/common';

type BlogCategoryType = {
  code: string;
  label: string;
  color: string;
};

export const useCommonStore = defineStore('common', () => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();

  const products = useState<ProductType[]>('products', () => []);
  const sections = useState<SectionType[]>('sections', () => []);
  const isTestGroup = useState('is-test-group', () => false);
  const blogCategories = useState<BlogCategoryType[]>(
    'blog-categories',
    () => [],
  );
  const pickUpPoints = ref<PickUpPointType[]>([]);

  const fetchCommon = async () => {
    const data = await CommonAPI.common();
    products.value = data.products;
    sections.value = data.sections;
    isTestGroup.value = data.isTestGroup || config.public.isTestGroup;
  };

  const fetchPickUpPoints = async () => {
    if (pickUpPoints.value.length) return;
    pickUpPoints.value = await CommonAPI.pickUpPoints();
  };

  watch(
    () => userStore.authorized,
    (_, prev) => {
      if (process.client && prev !== undefined) fetchCommon();
    },
  );

  return {
    products,
    sections,
    isTestGroup,
    fetchCommon,
    blogCategories,
    pickUpPoints,
    fetchPickUpPoints,
  };
});
