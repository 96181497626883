import { defineStore } from 'pinia';

export enum MessageModalType {
  success,
  error,
  info,
}

type MessageModalStateType = {
  type: MessageModalType;
  key: number;
  title: string;
  action: string;
  onClose?: (() => void) | null;
};

type MessageModalActionType = Omit<MessageModalStateType, 'type' | 'key'>;

type ConfirmationModalStateType = {
  title: string;
  visible: boolean;
  accept?: (() => void | Promise<void>) | null;
  decline?: (() => void) | null;
};

const generateKey = (list: { key: number }[]) =>
  Math.max(...list.map((el) => el.key), 0) + 1;

export const useModalStore = defineStore('modal', () => {
  const modalKeys = ref<Record<string, string>>({});
  const messageModalList = ref<MessageModalStateType[]>([]);
  const confirmationModal = reactive<ConfirmationModalStateType>({
    title: '',
    visible: false,
    accept: null,
    decline: null,
  });

  const openModal = (key: string) => {
    if (process.client) {
      modalKeys.value[key] = key;
      document.body.classList.add('overflow-hidden');
    }
  };

  const closeModal = (key: string) => {
    delete modalKeys.value[key];
    if (!Object.keys(modalKeys.value).length && process.client)
      document.body.classList.remove('overflow-hidden');
  };

  const toggleModal = (key: string, value = !modalKeys.value[key]) => {
    if (value) openModal(key);
    else closeModal(key);
  };

  const showSuccess = (options: MessageModalActionType) => {
    messageModalList.value.push({
      ...options,
      key: generateKey(messageModalList.value),
      type: MessageModalType.success,
    });
  };

  const showError = (options: MessageModalActionType) => {
    messageModalList.value.push({
      ...options,
      key: generateKey(messageModalList.value),
      type: MessageModalType.error,
    });
  };

  const showInfo = (options: MessageModalActionType) => {
    messageModalList.value.push({
      onClose: null,
      key: generateKey(messageModalList.value),
      type: MessageModalType.info,
      ...options,
    });
  };

  const closeMessageModal = () => messageModalList.value.shift();

  const showConfirmation = (
    options: Omit<ConfirmationModalStateType, 'visible'>,
  ) => {
    Object.assign(confirmationModal, {
      decline: null,
      visible: true,
      ...options,
    });
  };

  const closeConfirmationModal = () => {
    confirmationModal.visible = false;
  };

  const acceptConfirmationModal = () => {
    confirmationModal.visible = false;
  };

  const declineConfirmationModal = () => {
    confirmationModal.visible = false;
  };

  return {
    modalKeys,
    openModal,
    closeModal,
    toggleModal,

    messageModalList,
    showSuccess,
    showError,
    showInfo,
    closeMessageModal,

    confirmationModal,
    showConfirmation,
    closeConfirmationModal,
    acceptConfirmationModal,
    declineConfirmationModal,
  };
});
