import revive_payload_client_ECO4LqlQRb from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0twejYUvbW from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_belHXp5sUm from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PHYOlRSykN from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.12_sass@1.7_6hdij2dfb5e5nj4bjugwksfpre/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_9iW16N7b9H from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_U6VxhDO91Q from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_doQTkFHOxX from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1Jb9a9vj1e from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_etEC3TfaMB from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.19.0_typescript@5.5.4_vue@3.4.33_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_CkEJp8Nvh2 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_fkgbnltmim6qwy5ck7mxgeaq64/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_jJVs0jKWs6 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.12_sass@1.77.8_74ebujcrjojdwcqiq3rinklfw4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_CYlcDhgVje from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_JYqw4xsfwv from "/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import autosize_N8mFgrWbV7 from "/app/plugins/autosize.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_ECO4LqlQRb,
  unhead_0twejYUvbW,
  router_belHXp5sUm,
  _0_siteConfig_PHYOlRSykN,
  payload_client_9iW16N7b9H,
  navigation_repaint_client_U6VxhDO91Q,
  check_outdated_build_client_doQTkFHOxX,
  chunk_reload_client_1Jb9a9vj1e,
  plugin_vue3_etEC3TfaMB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CkEJp8Nvh2,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_jJVs0jKWs6,
  i18n_CYlcDhgVje,
  plugin_JYqw4xsfwv,
  autosize_N8mFgrWbV7,
  request_8cwBTcUfTa,
  sentry_client_shVUlIjFLk
]