import * as Sentry from '@sentry/vue';
import type { RuntimeConfig } from 'nuxt/schema';

import type { NuxtApp } from '#app/nuxt';
import { caseTransfer } from '~/api/utils/cases';
import type {
  FetchError,
  HookKey,
  Options,
  PluginOptionsType,
  PreparedRequestType,
  SchemasType,
} from '~/api/utils/types';
import {
  Case,
  HTTPError,
  HTTPMethod,
  RequestError,
  ResponseError,
} from '~/api/utils/types';

const configToOptions = (config: RuntimeConfig) => {
  const developmentBaseURLMapper = [
    {
      prefix: new RegExp(/^(?!client\/)/),
      value: '/api/server/',
    },
  ];

  const serverBaseURLMapper = [
    {
      prefix: new RegExp(/^(?!client\/)/),
      value:
        typeof window === 'undefined'
          ? `${config.public.siteUrl}/api/`
          : '/api/',
    },
  ];

  return {
    baseURLMapper:
      config.public.siteEnvironment === 'development'
        ? developmentBaseURLMapper
        : serverBaseURLMapper,
  };
};

const mergeOptions = <B, P, Q>(
  ...list: (Options<B, P, Q> | Options<B, P, Q>[] | undefined)[]
): Options<B, P, Q> =>
  list.reduce<Options<B, P, Q>>((acc, options) => {
    const mergedOptions = Array.isArray(options)
      ? mergeOptions(...options)
      : options;
    if (!mergedOptions) return acc;
    return {
      ...acc,
      ...mergedOptions,
      headers: { ...(acc.headers || {}), ...(mergedOptions.headers || {}) },
      baseURLMapper: [
        ...(acc.baseURLMapper || []),
        ...(mergedOptions.baseURLMapper || []),
      ],
    };
  }, {});

const request = {
  init: (options: {
    app: NuxtApp;
    logout: () => Promise<void>;
  }): PluginOptionsType => ({
    options: configToOptions(options.app.$config),
    logout: options.logout,
    orderRequests: {},
    orderHooks: {},
    methodSignals: {},
  }),

  hooks: {
    subscribe: (key: HookKey, handler: () => void) => {
      const { $request } = useNuxtApp();
      $request.orderHooks[key] = [...($request.orderHooks[key] || []), handler];
    },
    unsubscribe: (key: HookKey, handler: () => void) => {
      const { $request } = useNuxtApp();
      $request.orderHooks[key] = $request.orderHooks[key]?.filter(
        (el) => el !== handler,
      );
    },
  },

  execute: async <R, B, P, Q>(
    url: string,
    {
      orderKey,
      methodKey,
      groupKey: _,
      selfInterrupted,
      baseURLMapper,
      ...options
    }: Options<B, P, Q>,
    pluginOptions?: PluginOptionsType,
  ) => {
    const mapper = baseURLMapper?.find((item) => item.prefix.test(url));

    if (methodKey && pluginOptions) {
      pluginOptions.orderHooks[`method:${methodKey}:start`]?.forEach((el) =>
        el(),
      );
      if (selfInterrupted) {
        const interruptedKey =
          typeof selfInterrupted === 'function'
            ? `${methodKey}:${selfInterrupted(options)}`
            : methodKey;
        pluginOptions.methodSignals[interruptedKey]?.abort();
        pluginOptions.methodSignals[interruptedKey] = new AbortController();
      }
    }

    if (orderKey && pluginOptions) {
      await new Promise((r) => {
        if (pluginOptions.orderRequests[orderKey])
          pluginOptions.orderRequests[orderKey].push(r);
        else {
          pluginOptions.orderHooks[`order:${orderKey}:start`]?.forEach((el) =>
            el(),
          );
          pluginOptions.orderRequests[orderKey] = [r];
        }

        if (pluginOptions.orderRequests[orderKey][0] === r) r(true);
      });
    }

    return $fetch<R>(mapper ? url.replace(mapper.prefix, '') : url, {
      signal: methodKey
        ? pluginOptions?.methodSignals[methodKey]?.signal
        : null,
      baseURL: options.baseURL || (mapper?.value ?? '/api/'),
      ...options,
    }).finally(() => {
      if (!pluginOptions) return;

      if (methodKey) {
        pluginOptions.orderHooks[`method:${methodKey}:finish`]?.forEach((el) =>
          el(),
        );
        delete pluginOptions.methodSignals[methodKey];
      }

      if (orderKey) {
        pluginOptions.orderRequests[orderKey].shift();
        if (!pluginOptions.orderRequests[orderKey].length) {
          delete pluginOptions.orderRequests[orderKey];
          pluginOptions.orderHooks[`order:${orderKey}:finish`]?.forEach((el) =>
            el(),
          );
        } else pluginOptions.orderRequests[orderKey][0](true);
      }
    });
  },

  prepare: <
    R,
    B extends object | undefined = undefined,
    P extends object | undefined = undefined,
    Q extends Record<string, string> | undefined = undefined,
  >({
    url,
    method = HTTPMethod.get,
    schemas,
    options = {},
  }: {
    url: string | ((params: P) => string);
    method?: HTTPMethod;
    schemas?: SchemasType<R, B, P, Q>;
    options?: Options<B, P, Q> | Options<B, P, Q>[];
  }) => {
    const executor: PreparedRequestType<R, B, P, Q> = async (
      parameters,
      additionalOptions = {},
    ) => {
      const nuxtApp =
        typeof useNuxtApp !== 'undefined' ? useNuxtApp() : undefined;
      const token =
        typeof useTokenStore !== 'undefined'
          ? useTokenStore().value
          : undefined;
      // TODO: какая-то скрытая логика не очень удобная - стоит перейти на nuxt-strict-fetch и улучшить все это
      const cookies = nuxtApp?.ssrContext?.event.headers?.get('cookie');
      const locale = nuxtApp?.$i18n.locale.value;
      const isCypress =
        typeof useCookie !== 'undefined'
          ? useCookie<boolean>('cypress').value
          : undefined;
      const config = useRuntimeConfig();

      const baseOptions = mergeOptions<B, P, Q>(
        options,
        additionalOptions,
        nuxtApp?.$request.options,
      );

      try {
        const [body, params] = await Promise.all([
          (parameters &&
            'body' in parameters &&
            schemas?.body?.validate(parameters.body)) ||
            undefined,
          (parameters &&
            'params' in parameters &&
            schemas?.params?.validate(parameters.params)) ||
            undefined,
        ]);

        const data = await request.execute<R, B, P, Q>(
          typeof url === 'function' ? url(params as P) : url,
          mergeOptions<B, P, Q>(baseOptions, {
            headers: {
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
              ...(locale ? { 'X-Locale': locale } : {}),
              ...(isCypress ? { 'X-Autotest': isCypress.toString() } : {}),
              ...(cookies ? { Cookie: cookies } : {}),
            },
            method,
            body: body && caseTransfer(body, Case.snake),
            onRequestError(context) {
              throw new RequestError(
                `Fetch request error: ${
                  context.error.message || 'Empty request "message" parameter'
                }, ${context.request}`,
              )
                .from(context)
                .with(context.error);
            },
            onResponseError(context) {
              if (
                process.client &&
                context.response?.status === 401 &&
                baseOptions.groupKey !== 'auth'
              )
                nuxtApp?.$request.logout();

              throw new ResponseError(
                `Fetch response error: ${
                  context.response?._data?.message ||
                  context.response?.statusText ||
                  context.error?.message ||
                  'Empty error message'
                }, ${context.request}`,
              )
                .from(context, context.response?._data)
                .with(context.error);
            },
          }),
          nuxtApp?.$request,
        );

        const responseData = caseTransfer(data, Case.camel);
        return (
          schemas?.response
            ? await schemas.response.validate(responseData)
            : responseData
        ) as R;
      } catch (e) {
        const error = e as FetchError;
        if (error.name !== HTTPError.AbortError) {
          if (config.public.siteEnvironment !== 'production' || process.server)
            console.error(error);
          if (process.client) Sentry.captureException(error);
          baseOptions.onError?.(error);
        }
        throw error;
      }
    };

    executor.schemas = schemas;
    executor.withConfig = (config) => (p, o) =>
      executor(p, { ...configToOptions(config), ...o });

    return executor;
  },
};

export default request;
