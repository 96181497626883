<script lang="ts" setup>
import type { ButtonHTMLAttributes } from 'vue';
import type { VNode } from 'vue';

interface Props extends /* @vue-ignore */ ButtonHTMLAttributes {
  size?: 'm' | 's' | 'xs' | 'xxs';
  mobileSize?: 'm' | 's' | 'xs';
  outlined?: boolean;
  selected?: boolean;
  block?: boolean;
  dark?: boolean;
  icon?: 'caret' | 'plus' | 'check';
  loading?: boolean;
  contentClass?: string;
}

const props = withDefaults(defineProps<Props>(), { size: 'm' });
defineSlots<{
  default: () => VNode[];
}>();
</script>

<template>
  <button
    class="baseButton"
    :class="{
      [`baseButton_${size}`]: true,
      [`baseButton_${mobileSize}Mobile`]: mobileSize,
      baseButton_outlined: outlined,
      baseButton_dark: dark,
      baseButton_block: block,
      baseButton_withIcon: icon,
      baseButton_loading: loading,
      baseButton_selected: selected,
    }"
    :disabled="loading || props.disabled"
  >
    <span class="baseButton__content" :class="contentClass"><slot /></span>
    <Transition v-if="icon" name="reel">
      <LazySvgoSimpleCaret
        v-if="icon === 'caret'"
        :font-controlled="false"
        class="baseButton__icon"
      />
      <LazySvgoSimplePlus
        v-else-if="icon === 'plus'"
        :font-controlled="false"
        class="baseButton__icon"
      />
      <LazySvgoSimpleCheck
        v-else-if="icon === 'check'"
        :font-controlled="false"
        class="baseButton__icon"
      />
    </Transition>

    <Transition name="fade">
      <BaseLoader v-if="loading" class="baseButton__loader" :dark="dark" />
    </Transition>
  </button>
</template>

<style lang="scss">
.baseButton {
  $self: &;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 10px;
  height: 56px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #121212;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:disabled {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #878787;
    pointer-events: none;
  }

  &_s {
    font-size: 14px;
    padding: 0 29px;
    height: 48px;
    border-radius: 30px;
  }

  &_xs {
    font-size: 14px;
    padding: 10px 40px;
    height: 40px;
    border-radius: 30px;
  }

  &_xxs {
    font-size: 12px;
    padding: 0 30px;
    height: 36px;
    border-radius: 30px;
  }

  @include mq('sm') {
    &_xsMobile {
      font-size: 14px;
      padding: 10px 40px;
      height: 40px;
      border-radius: 30px;
    }
  }

  &_outlined {
    background-color: transparent;
    color: #ffffff;

    &:disabled {
      background-color: transparent;
      color: #c0c0c0;
      border-color: #c0c0c0;
      pointer-events: none;
    }
  }

  &_dark {
    background-color: #121212;
    color: #fff;
    border-color: #121212;

    &:not(:disabled)#{$self}_outlined {
      background-color: #ffffff;
      color: #121212;
      border-color: #121212;
    }
  }

  &_block {
    width: 100%;
    padding: 0 6px;
  }

  &_withIcon {
    padding: 0;
    justify-content: center;
    aspect-ratio: 1 / 1;
  }

  &_loading {
    #{$self}__content {
      opacity: 0;
    }

    #{$self}__icon {
      opacity: 0;
    }
  }

  &:not(:disabled):not(&_selected) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      color: #ffffff;
    }

    &#{$self}_outlined:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }

    &#{$self}_dark {
      &#{$self}_outlined:hover {
        background-color: #121212;
        color: #ffffff;
      }

      &:hover {
        background-color: #fff;
        color: #121212;
      }
    }
  }

  &__content {
    transition: opacity 0.3s ease;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:empty {
      display: none;
    }
  }

  &__icon {
    height: 11px;
    width: 20px;
    transition: opacity 0.3s ease;
    position: absolute;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
