<script lang="ts" setup>
const wrapper = ref(null);

defineExpose({ wrapper });

defineProps<{
  burger?: boolean;
  dark?: boolean;
}>();
</script>

<template>
  <button
    ref="wrapper"
    class="modalButton"
    :class="{ modalButton_opened: !burger, modalButton_dark: dark }"
  >
    <span class="modalButton__line" />
    <span class="modalButton__line" />
    <span class="modalButton__line" />
  </button>
</template>

<style lang="scss">
.modalButton {
  $self: &;

  width: 80px;
  height: 80px;
  padding: 0;
  border-radius: 50%;
  z-index: 11;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;

  &::before {
    content: '';
    height: inherit;
    width: inherit;
    display: block;
    background-color: #ffffff;
    border: 1px solid #fff;
    border-radius: inherit;
    transition:
      opacity 0.3s ease 0.2s,
      background-color 0.3s ease;
    box-sizing: border-box;
  }

  &:hover::before {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &_dark {
    filter: invert(1);
  }

  &_opened {
    &::before {
      transition: opacity 0.3s ease;
      opacity: 0;
    }

    #{$self}__line {
      width: 35px;
      background-color: #ffffff;
      transition:
        transform 0.3s ease,
        width 0.3s ease,
        background-color 0.3s ease;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  @include mq('md') {
    width: 60px;
    height: 60px;
  }

  @include mq('sm') {
    width: 56px;
    height: 56px;
  }

  &__line {
    height: 2px;
    background-color: #121212;
    width: 40px;
    position: absolute;
    border-radius: 1px;
    top: 50%;
    left: 50%;
    transition:
      transform 0.3s ease,
      width 0.3s ease,
      background-color 0.3s ease 0.2s;
    transform-origin: 50% 50%;

    &:nth-child(1) {
      transform: translate(-50%, calc(-50% - 10px));
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%);
    }

    &:nth-child(3) {
      transform: translate(-50%, calc(-50% + 10px));
    }

    @include mq('sm') {
      width: 32px;

      &:nth-child(1) {
        transform: translate(-50%, calc(-50% - 8px));
      }

      &:nth-child(3) {
        transform: translate(-50%, calc(-50% + 8px));
      }
    }
  }
}
</style>
