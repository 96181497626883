
import * as ipxRuntime$FzIBHfHAnz from '/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 768,
    "md": 1024,
    "lg": 1240,
    "xl": 1440,
    "xxl": 1920,
    "2xl": 1536,
    "xxxl": 2560
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$FzIBHfHAnz, defaults: {"maxAge":31536000} }
}
        