<script lang="ts" setup>
const wrapper = ref(null);

defineExpose({ wrapper });

defineProps<{
  dark?: boolean;
  count?: number;
}>();
</script>

<template>
  <button ref="wrapper" class="cartButton" :class="{ cartButton_dark: dark }">
    <SvgoSimpleCart filled class="cartButton__icon" />
    <Transition name="fade">
      <span v-if="count" class="cartButton__count">
        {{ count }}
      </span>
    </Transition>
  </button>
</template>

<style lang="scss">
.cartButton {
  width: 80px;
  height: 80px;
  padding: 0;
  border-radius: 50%;
  z-index: 11;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease;

  & &__icon {
    height: 40px;
    width: 32px;
    transition: opacity 0.3s ease;
    position: absolute;
    display: block;
    color: #fff;
    margin-bottom: 0;
    transform: translateY(-5%);
  }

  & &__count {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 19px;
    height: 19px;
    right: 35%;
    top: 40%;
    transform: translate(50%, -50%);

    background: #121212;
    border-radius: 35px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
  }

  &::before {
    content: '';
    height: inherit;
    width: inherit;
    display: block;
    background-color: #ffffff;
    border: 1px solid #fff;
    border-radius: inherit;
    transition:
      opacity 0.3s ease 0.2s,
      background-color 0.3s ease;
    box-sizing: border-box;
  }

  &:hover::before {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &_dark {
    filter: invert(1);

    &::before {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  @include mq('md') {
    width: 60px;
    height: 60px;
  }

  @include mq('sm') {
    width: 56px;
    height: 56px;

    & &__icon {
      height: 32px;
      width: 24px;
    }

    & &__count {
      width: 14px;
      height: 14px;
      font-size: 10px;
    }
  }
}
</style>
