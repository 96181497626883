<script lang="ts" setup>
import { BaseModalButton } from '#components';

const MODAL_KEY = 'main-menu';

const { t } = useI18n();
const commonStore = useCommonStore();
const modalStore = useModalStore();
const productCartStore = useProductCartStore();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const isBackNavigationButton = computed(() =>
  /^\/(auth|order)/.test(route.path),
);
const isBackNavigationButtonDark = computed(() => /^\/order/.test(route.path));

watch(
  () => route.fullPath,
  () => {
    modalStore.closeModal(MODAL_KEY);
  },
);

const onGlobalClick = () => {
  if (modalStore.modalKeys[MODAL_KEY]) {
    modalStore.closeModal(MODAL_KEY);
  }
};

const onOpenProductCart = () => {
  modalStore.closeModal(MODAL_KEY);
  productCartStore.open();
};

const onClickMenuButton = () => {
  const back = router.options.history.state.back;

  const navigateBack = () => {
    if (!back || route.path === back) router.push('/');
    else router.back();
  };

  if (modalStore.modalKeys[MODAL_KEY]) modalStore.closeModal(MODAL_KEY);
  else if (/^\/auth/.test(route.path)) {
    if (back && back !== '/profile') navigateBack();
    else router.push('/');
  } else if (/^\/order\/successful/.test(route.path)) router.push('/catalog');
  else if (/^\/order/.test(route.path)) navigateBack();
  else modalStore.openModal(MODAL_KEY);
};

onMounted(() => {
  window.addEventListener('click', onGlobalClick);
});

onBeforeUnmount(() => {
  modalStore.closeModal(MODAL_KEY);
  window.removeEventListener('click', onGlobalClick);
});
</script>

<template>
  <Transition name="fade">
    <BaseCartButton
      v-if="!isBackNavigationButton"
      @click.stop="onOpenProductCart"
      :dark="isBackNavigationButtonDark || !!modalStore.modalKeys[MODAL_KEY]"
      :count="productCartStore.totalCount"
      class="mainMenu__openCart"
      :aria-label="t('action.open_cart')"
      data-test-id="product-cart-toggle"
    />
  </Transition>
  <BaseModalButton
    @click.stop="onClickMenuButton"
    :burger="!modalStore.modalKeys[MODAL_KEY] && !isBackNavigationButton"
    :dark="isBackNavigationButtonDark"
    class="mainMenu__openButton"
    :aria-label="t('action.open_menu')"
    data-test-id="navigation-menu-toggle"
  />

  <BaseSidebar
    @update:visible="modalStore.toggleModal(MODAL_KEY, $event)"
    :visible="!!modalStore.modalKeys[MODAL_KEY]"
    full-height
    tag="nav"
    class="mainMenu"
    data-test-id="navigation-menu"
  >
    <div class="mainMenu__content">
      <div class="mainMenu__column">
        <NuxtLink to="/catalog" class="mainMenu__link">
          {{ t('link.catalog') }}
        </NuxtLink>
        <NuxtLink
          v-for="item in commonStore.sections"
          :key="item.id"
          :to="{ path: '/catalog', query: { categories: item.code } }"
          class="mainMenu__link"
        >
          {{ item.name }}
        </NuxtLink>
      </div>
      <div class="mainMenu__column">
        <NuxtLink to="/" class="mainMenu__link">
          {{ t('link.main') }}
        </NuxtLink>
        <NuxtLink to="/about" class="mainMenu__link">
          {{ t('link.about') }}
        </NuxtLink>
        <!-- TODO: может не нужно -->
        <!--        <NuxtLink to="/vacancies" class="mainMenu__link">-->
        <!--          Вакансии-->
        <!--        </NuxtLink>-->
        <NuxtLink to="/contacts" class="mainMenu__link">
          {{ t('link.contacts') }}
        </NuxtLink>
        <NuxtLink to="/faq" class="mainMenu__link">
          {{ t('link.faq') }}
        </NuxtLink>
        <NuxtLink to="/blog" class="mainMenu__link">
          {{ t('link.blog') }}
        </NuxtLink>
        <!-- TODO: может не нужно -->
        <!--        <NuxtLink to="/#playlists" class="mainMenu__link">-->
        <!--          Плейлисты-->
        <!--        </NuxtLink>-->
        <NuxtLink to="/delivery-and-payment" class="mainMenu__link">
          {{ t('link.delivery_and_payment') }}
        </NuxtLink>

        <div class="mainMenu__additional">
          <!-- TODO: может не нужно -->
          <!--          <NuxtLink to="/" class="mainMenu__subLink">-->
          <!--            Сертификаты-->
          <!--          </NuxtLink>-->
          <button @click="userStore.toProfile" class="mainMenu__subLink">
            {{ t('profile') }}
          </button>
        </div>
      </div>
    </div>
  </BaseSidebar>
</template>

<i18n>
ru:
  action:
    open_cart: Открыть корзину
    open_menu: Открыть навигационное меню
  link:
    main: Главная
    about: О бренде
    contacts: Контакты
    faq: FAQ
    delivery_and_payment: Доставка и оплата
    blog: Блог
    catalog: Все продукты
  profile: Аккаунт

en:
  action:
    open_cart: Open cart
    open_menu: Open navigation menu
  link:
    main: Main
    about: About
    contacts: Contacts
    faq: FAQ
    delivery_and_payment: Delivery and payment
    blog: Blog
    catalog: All products
  profile: Account
</i18n>

<style lang="scss">
.mainMenu {
  width: 720px;
  max-width: 100%;
  background: #121212;

  @include mq('sm') {
    width: 100%;
  }

  &__openButton {
    top: 28px;
    right: 20px;
    position: fixed;

    @include mq('sm') {
      top: 10px;
      right: 5px;
    }
  }

  &__openCart {
    top: 28px;
    right: 114px;
    position: fixed;

    @include mq('md') {
      right: 100px;
    }

    @include mq('sm') {
      top: 10px;
      right: 69px;
    }
  }

  &__content {
    padding: 94px 60px 48px;
    display: flex;
    gap: 34px;

    @include mq('sm') {
      padding: 32px 16px;
      flex-direction: column;
      height: auto;
      gap: 52px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &:nth-child(1) {
      width: 301px;
    }

    @include mq('sm') {
      gap: 10px;

      &:nth-child(1) {
        margin-top: 34px;
        width: initial;
      }
    }
  }

  &__link {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #ffffff;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    @include mq('sm') {
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__additional {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;

    @include mq('sm') {
      margin-top: 42px;
    }
  }

  &__subLink {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
    transition: opacity 0.3s ease;
    background: none;
    padding: 0;
    border: none;
    text-align: start;
    cursor: pointer;
    width: fit-content;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
